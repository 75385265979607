import TopNavLayout from '@/layouts/TopNavView'
import profile from './profile'

// Create a route with navigation in the header.
export default [
  {
    path: '/topNavLayout',
    component: TopNavLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: 'Tickitin'
        },
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/search',
        name: 'search',
        meta: {
          title: 'search'
        },
        component: () => import('@/views/search/index.vue')
      },
      {
        path: '/download/app',
        name: 'downloadApp',
        meta: {
          title: 'download app'
        },
        component: () => import('@/views/download/DownloadApp.vue')
      },
      {
        path: '/activity/:id',
        name: 'activityDetail',
        component: () => import('@/views/activity/index.vue'),
      },
      {
        path: '/ticket/:id',
        name: 'ticketDetail',
        component: () => import('@/views/ticket/index.vue'),
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/views/purchase/index.vue'),
      },
      {
        path: '/order/status',
        name: 'orderStatus',
        component: () => import('@/views/order/OrderStatus.vue'),
      }
    ]
  }
]
